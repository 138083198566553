/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on July 10, 2023 */
.embed-responsive {
    margin-top: 93px;
    margin-bottom: 20px;
}

@font-face {
    font-family: 'komika_axisregular';
    src: url('komikax_-webfont.woff2') format('woff2'),
         url('komikax_-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}