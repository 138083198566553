.footer_EU {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(52, 58, 64);
    padding: 20px;
    color: white;
}

.containerLogo {
    margin-bottom: 20px;
}

.utrgv-foot-logo {
    width: 340px;
    margin-top: 8px;
}

.navigation-links {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
}

.navigation-links a {
    color: white;
    text-decoration: none;
}

.navigation-links a:hover {
    text-decoration: underline;
}

.social-media-footer {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
}

.social-media-footer img {
    width: 25px;
}

.middle-section-footer {
    text-align: center;
}
