.header_EU {
  height: 55px;
  display: flex;
  position: absolute;
  flex-direction: row;
  top: 50px;
  left: 0;
  right: 0;
  background-color: rgba(216, 186, 93, 0.422);
  justify-content: space-between;
  z-index: 1;
}

.left-section-EU {
  display: flex;
  flex: 1;
  height: 100%;
}

.middle-section-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.middleTitle {
  font-size: 3.5vw;
  font-family: 'komika_axisregular';
  color: rgb(255, 255, 255);
  -webkit-text-stroke-color: rgb(0, 0, 0);
  -webkit-text-stroke-width: 2.2px;
  margin-bottom: 13px;
}

.right-section-EU {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  height: 100%;
  flex-direction: row;
}

.language-switcher {
  display: flex;
  align-self: center;
  align-items: center;
  border: 2px solid #fff;
  border-radius: 23px;
  font-weight: bold;
  order: 2;
  margin-right: 5px;
  /* Add this line */
}


.language-switcher a {
  text-decoration: none;
  color: white;
  opacity: 50%;
  padding: 5px 10px;
}

.language-switcher a.selected {
  color: white;
  opacity: 100%;
}


.language-divider {
  padding: 0 2px;
  color: white;
}

.Activities {
  margin-right: 24px;
  order: 0;
  /* First item */
}

.FAQ {
  margin-right: 25px;
  order: 1;
  /* Second item */
}

.Downloads, .Home {
  margin-right: 24px;
  order: 3; 
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: white; 
  font-weight: bold;
  border: none; 
  background-color: transparent; 
  box-shadow: none; 
  padding: 0; 
}

.Downloads:hover {
  text-decoration: none;
  color: rgb(17, 150, 12);
}

.Home:hover, a.Home:hover {
  color: rgb(17, 150, 12); /* Or use var(--secondary-color) if you have it defined */
}

a.FAQ,
a.Activities,
a.Downloads,
a.Home {
  text-decoration: none;
}

.Activities,
a.Activities,
.FAQ {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: white;
  font-weight: bold;
}

.Activities:hover,
a.FAQ:hover {
  text-decoration: none;
  color: rgb(17, 150, 12);
}

.rotate-center {
  position: absolute;
  width: 126px;
  left: 100px;
  top: -25px;
  background-color: rgba(235, 172, 46, 0.671);
  border-radius: 10px;
  padding: 7px;
}

.rotate-center:hover {
  -webkit-animation: rotate-center 0.6s ease-in-out both;
  animation: rotate-center 0.6s ease-in-out both;
}

.news-content {
  flex: 1;
  width: 100%;
  padding: 0 5px;
}



@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


.my-custom-inline-block {
  display: inline-block !important;
}

@media (max-width: 936px) {
  .my-custom-inline-block {
    display: none;
  }
}


@media (max-width: 936px) {

  
  .rotate-center {
    display: none;
  }

  
  .middleTitle {
    display: block;
    margin-bottom: 9px;
    font-size: 35px;
  }

  .header_EU {
    height: 38px;
    top: 0;
  }

  .right-section-EU {
    position: relative;
    margin-right: 0px;
  }

  .dropdown {
    display: block;
    position: absolute;
    right: -7.5px;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    top: 100%;
    border: none;
    z-index: 100;
  }

  .btn.green-dropdown-btn {
    --bs-btn-bg: rgb(8, 154, 30);
    --bs-btn-border-color: rgb(8, 154, 30);
    --bs-btn-hover-bg: rgb(6, 84, 17);
    --bs-btn-hover-border-color: rgb(6, 84, 17);
    --bs-btn-active-bg: rgb(6, 84, 17);
    --bs-btn-active-border-color: rgb(6, 84, 17);
    --bs-btn-disabled-bg: rgb(8, 154, 30);
    --bs-btn-disabled-border-color: rgb(8, 154, 30);
    border-radius: 1;
  }
  
  .show>.btn.green-dropdown-btn.dropdown-toggle {
    color: #fff;
    background-color: #4CAF50;
    border-color: #388E3C;
  }
  
  .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color:#4CAF50;
    border-color:#388E3C;
  }

  .language-link {
    display: block;
    width: 100%;
    padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
    clear: both;
    font-weight: 400;
    color: var(--bs-dropdown-link-color);
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    border-radius: var(--bs-dropdown-item-border-radius, 0);
    font-weight: bold;
  }

  .language-link:hover {
    color: inherit;
    text-decoration: none;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .Activities,
  .FAQ,
  .language-switcher {
    margin: 0;
    padding: 0px 10px;
  }

  .green-dropdown-btn {
    background-color: #4CAF50;
    border: none;
    color: #ffffff;
    font-weight: bold;
    transition: background-color 0.3s;
  }

  .green-dropdown-btn:hover,
  .green-dropdown-btn:focus {
    background-color: #45a049;
    text-decoration: none;
  }

  .green-dropdown-btn i {
    margin-left: 5px;
  }

}

@media (max-width: 857px) {

  .Activities,
  .FAQ,
  .language-switcher,
  .Downloads {
    margin: 6px;
    padding: 0px 0px;
  }
}

@media (max-width: 815px) {
  .dropdown {
    display: block;
  }

  .d-md-none {
    display: block !important;
  }

  .d-md-inline-block {
    display: none !important;
  }
}

@media (min-width: 815px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }
}

/*This section is to fix the dropdown issue*/

@media (max-width: 768px) {
  .dropdown-item {
    color: black;
  }

  .dropdown-item:hover, .dropdown-item:focus {
    color: white;
    background-color: #007bff;
  }

  .language-link, .Activities, .Downloads, .FAQ, .Home {
    color: black !important;
  }

  .header_EU .dropdown-menu .dropdown-item {
    color: black !important;
  }

  .middleTitle {
    font-size: 6.5vw;
    -webkit-text-stroke-width: 1.5px;
  }

 

}
