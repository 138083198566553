body.TOP {
  background-image: url('images/website-background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  height: 100%;
  margin: 0;
  overflow: hidden;
}

.team-member {
  width: calc(20% - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;

}

.card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}


.team-names {
  font-family: 'komika_axisregular';
  text-align: center;
  margin-top: 10px;
  z-index: 1;
  position: relative;
}


.meet-section {
  padding: 225px;
  text-align: center;
}

.team-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 140px;
}


.team-card {
  width: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team-card.flipped {
  transform: rotateY(180deg);
}

.front,
.back {
  backface-visibility: hidden;
  width: 100%;
}

.back {
  transform: rotateY(180deg);
  position: absolute;
}

.lozanoImg {
  size: 10px;
}

img,
svg {
  vertical-align: middle;
}