.meet-section {
    background-image: url('images/website-background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    height: 100%;
    margin: 0;
    overflow: hidden;
}

.game-background {
    background-image: url('images/website-background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    height: 120vh;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
}

.custom-container {
    max-width: 1310px;
    margin: 0 auto;
    padding: 3rem;
    text-align: center;
}

.custom-row {
    display: flex;
    justify-content: center;
}

.custom-column {
    width: 100%;
    padding: 1rem;
}

.custom-embed {
    position: relative;
    width: 960px; /* width of your iframe */
    padding-bottom: 56.25%; /* aspect ratio for 16:9, you can adjust if you know your game's ratio */
    margin: 0 auto; /* center the embed on the page */
    background: #333;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
}


.custom-embed iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; /* this will make it responsive */
    height: 100%; /* this will make it responsive */
    border: 0;
}

