.top-section {
    background: center / cover url("images/website-background.jpg") no-repeat;
    height: 105px;
    margin-bottom: 0px;
}


.faq-section {
    background-color: rgba(184, 185, 181, 0.19);
    border-radius: 15 px;
    font-family: 'Montserrat', sans-serif;
    padding-bottom: 35px;
}

.faq-header {
    background-color: #fff;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 15px;
}


.carousel-inner img {
    width: 100%;
    max-height: 450px;
    object-fit: cover;
}


.faq-question {
    font-size: 24px;
    color: var(--primary-color);
    margin-bottom: 15px;
    margin-top: 25px;
    font-weight: bold;
}

.faq-item {
    padding: 0 60px;
}


.faq-answer {
    font-size: 18px;
    line-height: 1.5;
}

.faq-section a {
    color: var(--secondary-color);
    text-decoration: none;
}

.faq-section a:hover {
    text-decoration: underline;
}